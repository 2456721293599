<template>
  <div class="page">
    <div class="zhanwei">
      <!-- 占位 -->
    </div>
    <div class="top container">
      <div class="title">
        <img src="@/assets/login/pay_right.png" alt="" />
        报名成功！
      </div>
      <template v-for="(item, index) in good">
        <div class="price">
          <div class="name">
            {{ item.good_type == 3 ? item.mock.mock_title + " —— " : "" }}
            {{ item.good_name }}
          </div>
          <div class="num">
            <p>×{{ item.buy_nums }}</p>
            <p class="order_price">
              {{ item.is_free == 1 ? "￥" + item.good_present_price : "免费" }}
            </p>
          </div>
        </div>
      </template>

      <div class="btn_wrap">
        <router-link to="/">
          <div class="back_index btn">返回首页</div>
        </router-link>
        <template v-if="good[0]">
          <template v-if="good[0].good_type == 1">
            <div v-if="good[0].course.course_type==2" class="back_course btn" @click="toWatch">课程详情</div>
            <div v-else class="back_course btn" @click="toWatch">进入课程</div>
          </template>
          <template v-else-if="good[0].good_type == 3">
            <div class="back_course btn" @click="toWatch">查看详情</div>
          </template>
        </template>
      </div>
    </div>
    <template v-if="market">
      <!-- 营销类型 1公众号  2QQ群  3个人微信或QQ -->
      <div v-if="market.type == 1" class="bottom container">
        <img :src="market.market_url" alt="" />
        <div class="right_wrap">
          <p class="tip">{{ market.market_desc }}</p>
          <!-- <div class="copy_wrap" v-clipboard:copy="message" v-clipboard:success="onCopy" v-clipboard:error="onError">
          <p>微信号：{{message}}</p>
          <div class="btn_copy">复制</div>
        </div> -->
        </div>
      </div>
      <div v-if="market.type == 2" class="bottom container"></div>
      <div v-if="market.type == 3" class="bottom container">
        <img :src="market.market_url" alt="" />
        <div class="right_wrap">
          <p class="tip">{{ market.market_name }}</p>
          <p class="tip">{{ market.market_num }}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import api from "@/config/api";
export default {
  components: {},
  data() {
    return {
      message: "successedufw",
      good: [],
      market: null,
    };
  },
  mounted() {
    if (location.search) {
      var new_url = location.origin + location.pathname;
      location.href = new_url;
    }
    this.getData(this.$route.params.o);
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  methods: {
    async getData(oid) {
      try {
        let res = await api.pay_success({
          id: oid,
        });
        if (res.code == 0) {
          this.good = res.result.good;
          if (res.result.market) {
            this.market = res.result.market;
          }
        } else {
          this.$message(res.$message);
        }
      } catch (e) {
        console.log(e);
      }
    },
    toWatch() {
      var firstPro = this.good[0];
      if (firstPro.good_type == 1) {
        if(firstPro.course.course_type==2){
          this.$router.push({ path: "/course_info/" + firstPro.relation_id });
        }else{
          this.$router.push({ path: "/course_watch/" + firstPro.relation_id });
        }
      } else if (firstPro.good_type == 3) {
        location.href = `https://tk.successkaoyan.com/mock_intro/${firstPro.mock.m_id}`;
      }
    },
    onCopy: function (e) {
      this.$notify({
        title: `订单编号：${e.text}`,
        message: "复制成功",
        type: "success",
      });
    },
    onError: function (e) {
      alert("Failed to copy texts");
    },
    handleImg(url) {
      return url.replace(/http/g, "https");
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="less" scoped>
.page {
  background: #f5f6f8;
  min-height: 70vh;
}
.zhanwei {
  height: 22px;
}
.top {
  width: 1200px;
  height: 314px;
  background: #ffffff;
  border-radius: 16px;
  padding: 30px 40px;
  box-sizing: border-box;
  margin-bottom: 24px;
  .title {
    width: 1120px;
    height: 73px;
    background: #fdf8ec;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    box-sizing: border-box;
    margin-bottom: 30px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 14px;
    }
    font-size: 20px;

    font-weight: 600;
    color: #c07935;
    line-height: 38px;
  }
  .price {
    text-align: left;
    font-size: 20px;

    font-weight: 600;
    color: #333333;
    margin-bottom: 40px;
    .name {
      margin-bottom: 5px;
    }
    .num {
      font-size: 16px;

      font-weight: 500;
      color: #999999;
      line-height: 38px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .order_price {
        font-size: 22px;

        font-weight: 600;
        color: #333333;
        line-height: 26px;
      }
    }

    // line-height: 38px;
  }
  .btn_wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
      width: 160px;
      height: 40px;
      border: 1px solid #999999;
      border-radius: 20px;
      font-size: 18px;

      font-weight: 500;
      color: #999999;
      line-height: 40px;
      cursor: pointer;
    }
    .back_course {
      width: 160px;
      height: 40px;
      background: #479dff;
      border-radius: 20px;
      font-size: 18px;

      font-weight: 500;
      color: #ffffff;
      border: none;
      margin-left: 20px;
    }
  }
}
.bottom {
  width: 1200px;
  height: 171px;
  background: #ffffff;
  border-radius: 16px;
  text-align: left;
  padding: 30px 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  img {
    width: 110px;
    height: 110px;
    margin-right: 16px;
  }
  .right_wrap {
    p.tip {
      font-size: 20px;

      font-weight: 600;
      color: #333333;
      margin-bottom: 8px;
    }
    .copy_wrap {
      display: flex;
      align-items: center;
      width: fit-content;
      p {
        font-size: 20px;

        font-weight: 600;
        color: #333333;
        line-height: 38px;
      }
      .btn_copy {
        width: 40px;
        height: 20px;
        border: 1px solid #999999;
        border-radius: 10px;
        font-size: 12px;

        font-weight: 500;
        color: #999999;
        text-align: center;
        line-height: 20px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}
</style>
